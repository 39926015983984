<template>
  <div>
    <div v-for="page in $pages" :key="page.number" class="page-list">
        <a @click="goToPage(page.number)">page {{page.number}}</a>
    </div>
  </div>
</template>

<script>
export default {
    name: 'PageList',
    methods: {
        goToPage(pageNumber) {
            this.$router.push(`/page/${pageNumber}`)
        }
    }
}
</script>

<style>

</style>