import Vue from 'vue'
import VueRouter from 'vue-router'
import PageView from '@/views/PageView.vue'
import PageList from '@/views/PageList.vue'
import AboutPage from '@/views/AboutPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: PageView
  },
  {
    path: `/page/:id`,
    name: `page`,
    component: PageView
  },
  {
    path: `/page-list`,
    name: `page`,
    component: PageList
  },
  {
    path: `/about`,
    name: `AboutPage`,
    component: AboutPage
  }
];

const router = new VueRouter({
  routes
})

export default router
