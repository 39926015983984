<template>
  <div>
    <div><img :src="getImgUrl(1)" /></div>
    <div v-if="$pages[pageNumber - 1].panel2"><img :src="getImgUrl(2)" /></div>
    <div v-if="$pages[pageNumber - 1].panel3"><img :src="getImgUrl(3)" /></div>
    <div id="page-nav">
        <a @click="first" :class="{'disabled':isFirstPage}">&lt;&lt;</a>
        <a @click="previous" :class="{'disabled':isFirstPage}">&lt;</a>
        <a @click="menu" id="menu">&equiv;</a>
        <a @click="next" :class="{'disabled':isLastPage}">&gt;</a>
        <a @click="last" :class="{'disabled':isLastPage}">&gt;&gt;</a>
    </div>
  </div>
</template>

<script>
export default {
    name: "PageView",
    data() {
        return {
            pageNumber: this.$route.params.id,
            isFirstPage: null,
            isLastPage: null,
        }
    },
    created() {
        window.scrollTo(0,0);
        this.pageNumber = this.$route.path === '/' ?  this.$pages.length : this.$route.params.id;
        this.isFirstPage= this.pageNumber === "1";
        this.isLastPage= Number(this.pageNumber) === this.$pages.length;
    },
    methods:{
        getImgUrl(pic) {
            return require('../assets/P' + this.pageNumber + '-' + pic + '.jpg');
        },
        first() {
            if (!this.isFirstPage) {
                this.$router.push(`/page/1`)
            }
        },
        previous() {
            if (!this.isFirstPage) {
                this.$router.push(`/page/${Number(this.pageNumber) - 1}`)
            }
        },
        menu() {
            this.$router.push(`/page-list`)
        },
        next() {
            if (!this.isLastPage) {
                this.$router.push(`/page/${Number(this.pageNumber) + 1}`)
            }
        },
        last() {
            if (!this.isLastPage) {
                this.$router.push(`/page/${this.$pages.length}`)
            }
        }
    }
}
</script>

<style>

</style>