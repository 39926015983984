<template>
  <div>
      <article>
        <p>-</p>
        <p>greygar is an independent wordless webcomic</p>
        <p>-</p>
        <p>written by jack brissenden</p>
        <p>artwork by elioh kortsarz</p>
        <p>-</p>
        <p>greygar is a future</p>
        <p>greygar is a past</p>
        <p>greygar is a present</p>
        <p>-</p>
        <p>greygar.comic@gmail.com</p>
        <p>-</p>
      </article>
      <footer>
        &copy; special zone comics 2023
      </footer>
  </div>
</template>

<script>
export default {
    name: 'AboutPage'
}
</script>

<style>

</style>